

@media (min-width: 1080px) {
  :root {
    --cache-version: 1;
  }
}


@media (min-width: 1080px) {
  .hide-on-desktop {
    display: none;
  }
}


@media (min-width: 1080px) {
  html, body, .font-body {
    font-size: 16px;
  }
}


@media (min-width: 1080px) {
  .footer__themes-logo img {
    max-width: 104px;
  }

  .footer__categories--wrapper {
    gap: var(--spacing-32);
  }

  .footer__categories--wrapper .footer__category {
    flex-basis: 16.6667%;
  }
}


@media (min-width: 1080px) {
  .footer__contacts {
    order: 2;
  }

  .footer__contacts-links {
    font: var(--font-desktop-body2-bold);
    justify-content: center;
  }

  .footer__applications-wrapper {
    gap: var(--spacing-48);
    flex-direction: row;
    align-items: center;
  }

  .footer__applications {
    gap: var(--spacing-48);
  }

  .footer__logo {
    display: none;
  }
}


@media (min-width: 1080px) {
  .footer__copyright {
    font: var(--font-desktop-meta-regular);
  }
}


@media (min-width: 1080px) {
  .container--main {
    grid-template-rows: auto auto auto;
    grid-template-columns: auto minmax(981px, 1080px) auto;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    display: grid;
    position: relative;
  }
}


@media (min-width: 1080px) {
  header {
    position: relative;
  }
}


@media (min-width: 1080px) {
  header.is-pinned {
    animation: .3s forwards slide-down;
  }
}


@media (min-width: 1080px) {
  header:not(.is-pinned) + .header-static {
    padding-top: 0;
  }
}


@media (min-width: 1080px) {
  .header-static {
    gap: var(--spacing-24);
    grid-template-rows: auto;
    grid-template-columns: [lend] 1fr[ldivider] 1fr[rdivider] 1fr[rend];
  }
}


@media (min-width: 1080px) {
  .wrapper {
    padding: var(--spacing-24) var(--spacing-24) var(--spacing-32) var(--spacing-24);
  }
}


@media (min-width: 1080px) {
  .wrapper--article-detail {
    padding-top: 0;
  }
}


@media (min-width: 1080px) {
  .wrapper__aside {
    display: block;
  }
}


@media (min-width: 1080px) {
  .grid-113, .grid-233 {
    grid-column: lend / rend;
    grid-template-columns: [lend] 1fr[ldivider] 1fr[rdivider] 1fr[rend];
  }
}


@media (min-width: 1080px) {
  .container--one-column .main--first-content, .container--one-column .main--second-content {
    padding: var(--spacing-24) 176px 0 176px;
  }
}


@media (min-width: 1080px) {
  .container--one-column .article-layout {
    padding-top: 0;
  }

  .container--one-column .article-layout .grid-position-2 {
    grid-area: 2 / 1 / 3 / 3;
    padding: 0 176px;
  }

  .container--one-column .article-layout .grid-position-3 {
    grid-area: 3 / 1 / 4 / 3;
    padding: 0 176px;
  }
}


@media (min-width: 1080px) {
  .container--one-column .grid-233 {
    grid-template-columns: [lend] 1fr 1fr[rend];
  }
}


@media (min-width: 1080px) {
  footer:not(.footer--blesksport) {
    box-shadow: -50vw 0 0 var(--color-surface-default-strong), -25vw 0 0 var(--color-surface-default-strong), 25vw 0 0 var(--color-surface-default-strong), 50vw 0 0 var(--color-surface-default-strong);
  }
}


@media (min-width: 1080px) {
  .header {
    padding: var(--spacing-8) var(--spacing-24);
    max-width: 1080px;
  }

  .is-pinned .header {
    width: auto;
    max-width: 1920px;
  }

  header:not(.is-pinned) {
    box-shadow: none;
  }

  header:not(.is-pinned) .header {
    height: var(--spacing-header-height);
    gap: var(--spacing-header-v-gap) var(--spacing-24);
    border-radius: var(--spacing-header-top-left-radius) var(--spacing-header-top-right-radius) var(--spacing-header-bottom-right-radius) var(--spacing-header-bottom-left-radius);
    flex-wrap: wrap;
    box-shadow: 0 4px 20px #0000001a;
  }
}


@media (min-width: 1080px) {
  .header-search {
    display: block;
    position: relative;
  }
}


@media (min-width: 1080px) {
  header:not(.is-pinned) .header-socials {
    flex: 1 1 0;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
}


@media (min-width: 1080px) {
  .header-user__settings-text, .header-user__discussion-text, .header-user__logout-text {
    padding-top: var(--spacing-2);
  }
}


@media (min-width: 1080px) {
  .header-logo {
    margin-right: var(--spacing-32);
  }

  header:not(.is-pinned) .header-logo {
    flex: 0 1 0;
    margin-right: 0;
  }
}


@media (min-width: 1080px) {
  header:not(.is-pinned) .header-controls {
    flex: 1;
  }
}


@media (min-width: 1080px) {
  .is-pinned .header-desktop-navigation {
    order: initial;
  }

  .is-pinned .header-desktop-navigation:not(:has(.overflow-menu.active)) {
    overflow: hidden;
  }

  .is-pinned .header-menu {
    max-width: 100%;
  }

  header:not(.is-pinned) .header-desktop-navigation {
    order: 1;
    width: 100%;
  }

  header:not(.is-pinned) .header-menu__list {
    justify-content: center;
  }
}


@media (min-width: 1080px) {
  .header-menu__item {
    font: var(--font-desktop-menu-item);
    line-height: 26px;
  }
}


@media (min-width: 1080px) {
  .header-menu-hamburger {
    cursor: pointer;
  }
}


@media (min-width: 1080px) {
  .megamenu-wrapper {
    padding: var(--spacing-32) var(--spacing-24);
    gap: var(--spacing-32);
    grid-template: [r1-start] "logo search search search search close" [r1-end]
                   [r2-start] "stickers stickers stickers stickers stickers stickers" [r2-end]
                   [r3-start] "categories categories categories categories print print" [r3-end]
                   [r4-start] "categories categories categories categories print print" [r4-end]
                   [r5-start] "other-categories other-categories other-categories other-categories contact contact" [r5-end]
                   [r6-start] "divider divider divider divider divider divider" [r6-end]
                   [r7-start] "social newsletter newsletter applications applications applications" [r7-end]
                   / 2fr 1fr 1fr 1fr 2fr 0fr;
  }

  .is-pinned .megamenu-wrapper {
    left: calc(50% - 541px);
  }
}


@media (min-width: 1080px) {
  .megamenu-close {
    justify-content: flex-end;
    gap: var(--spacing-8);
    cursor: pointer;
    color: var(--text-primary);
    font: var(--font-desktop-button-large);
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    display: flex;
  }

  .megamenu-close svg * {
    fill: var(--color-icon-icon-button-primary-solid-normal-text-default);
  }

  .megamenu-close:hover {
    text-decoration: underline;
  }

  .megamenu-close:hover svg * {
    fill: var(--color-icon-icon-button-primary-solid-normal-text-hover);
  }
}


@media (min-width: 1080px) {
  .megamenu-categories {
    gap: var(--spacing-32);
    row-gap: var(--spacing-24);
    flex-wrap: wrap;
    width: 675px;
  }

  .megamenu-categories__item {
    row-gap: var(--spacing-12);
    flex-basis: 144px;
  }

  .megamenu-categories .megamenu-subcategories {
    gap: var(--spacing-8);
  }

  .megamenu-categories--other .megamenu-subcategories {
    gap: var(--spacing-10) var(--spacing-40);
  }
}


@media (min-width: 1080px) {
  .megamenu-print {
    gap: var(--spacing-12);
    min-width: 210px;
  }

  .megamenu-print__title {
    font: var(--font-desktop-h4);
  }

  .megamenu-print__subtitle {
    font: var(--font-desktop-body2);
  }

  .megamenu-print__covers {
    gap: var(--spacing-32);
    flex-direction: column;
  }
}


@media (min-width: 1080px) {
  .megamenu-logo {
    margin-right: var(--spacing-32);
    display: flex;
  }
}


@media (min-width: 1080px) {
  header.is-pinned .header .megamenu-backdrop--header {
    z-index: 4;
    display: block;
  }
}


@media (min-width: 1080px) {
  .section--columns-233 {
    grid-column: 1 / 4;
  }
}


@media (min-width: 1080px) {
  .section--columns-232 {
    grid-column: 1 / 3;
  }
}


@media (min-width: 1080px) {
  .article-header {
    margin: 0 calc(-1 * var(--spacing-24));
    padding: var(--spacing-24) var(--spacing-24) 0 var(--spacing-24);
  }

  .article-header .space-below-header {
    margin: calc(-1 * var(--spacing-24)) calc(-1 * var(--spacing-24)) 0;
  }
}


@media (min-width: 1080px) {
  .article-header--blesksport .space-below-header {
    margin: 0;
  }
}


@media (min-width: 1080px) {
  .article-layout {
    gap: var(--spacing-32);
    grid-template-columns: 700px 300px;
    justify-content: center;
  }

  .article-layout .grid-position-1 {
    grid-area: 1 / 1 / 2 / 3;
  }

  .article-layout .grid-position-2 {
    grid-area: 2 / 1 / 3 / 2;
  }

  .article-layout .grid-position-3 {
    grid-area: 3 / 1 / 4 / 2;
  }

  .article-layout .grid-position-4 {
    grid-area: 2 / 2 / 4 / 3;
    display: block;
  }
}


@media (min-width: 1080px) {
  .article-layout--blesksport {
    margin-bottom: var(--spacing-32);
  }

  .article-layout--blesksport .grid-position-3 {
    grid-area: 3 / 1 / 2 / 2;
  }
}


@media (min-width: 1080px) {
  .progress-bar {
    position: absolute;
  }
}


@media (min-width: 1080px) {
  .typography--h1, .typography--heading-3xl {
    font: var(--typography-desktop-heading-3xl-font-size) / var(--typography-desktop-heading-3xl-line-height) var(--heading-3xl-font-family);
  }

  .typography--heading-2xl {
    font: var(--typography-desktop-heading-2xl-font-size) / var(--typography-desktop-heading-2xl-line-height) var(--heading-2xl-font-family);
  }

  .typography--h2, .typography--heading-xl {
    font: var(--typography-desktop-heading-xl-font-size) / var(--typography-desktop-heading-xl-line-height) var(--heading-xl-font-family);
  }

  .typography--h3, .typography--heading-lg {
    font: var(--typography-desktop-heading-lg-font-size) / var(--typography-desktop-heading-lg-line-height) var(--heading-lg-font-family);
  }

  .typography--h4, .typography--heading-md {
    font: var(--typography-desktop-heading-md-font-size) / var(--typography-desktop-heading-md-line-height) var(--heading-md-font-family);
  }

  .typography--h5, .typography--heading-sm {
    font: var(--typography-desktop-heading-sm-font-size) / var(--typography-desktop-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .typography--heading-xs {
    font: var(--typography-desktop-heading-xs-font-size) / var(--typography-desktop-heading-xs-line-height) var(--heading-xs-font-family);
  }

  .typography--heading-2xs {
    font: var(--typography-desktop-heading-2xs-font-size) / var(--typography-desktop-heading-2xs-line-height) var(--heading-2xs-font-family);
  }

  .typography--inter-title {
    font: var(--typography-desktop-inter-title-font-size) / var(--typography-desktop-inter-title-line-height) var(--inter-title-font-family);
  }

  .typography--section-title {
    font: var(--typography-desktop-section-title-font-size) / var(--typography-desktop-section-title-line-height) var(--section-title-font-family);
  }

  .typography--subtitle1, .typography--subtitle-lg {
    font: var(--typography-desktop-subtitle-lg-font-size) / var(--typography-desktop-subtitle-lg-line-height) var(--subtitle-lg-font-family);
  }

  .typography--subtitle1-bold, .typography--subtitle-lg-bold {
    font: 700 var(--typography-desktop-subtitle-lg-font-size) / var(--typography-desktop-subtitle-lg-line-height) var(--subtitle-lg-bold-font-family);
  }

  .typography--subtitle2, .typography--subtitle-md {
    font: var(--typography-desktop-subtitle-md-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-font-family);
  }

  .typography--subtitle2-bold, .typography--subtitle-md-bold {
    font: var(--typography-desktop-subtitle-md-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-bold-font-family);
  }

  .typography--body1, .typography--body-lg {
    font: var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-font-family);
  }

  .typography--body1-bold, .typography--body-lg-bold {
    font: 700 var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-bold-font-family);
  }

  .typography--body2, .typography--body-md {
    font: var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-font-family);
  }

  .typography--body2-bold, .typography--body-md-bold {
    font: 700 var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-bold-font-family);
  }

  .typography--meta-regular, .typography--body-sm {
    font: var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-font-family);
  }

  .typography--meta-bold, .typography--body-sm-bold {
    font: 700 var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-bold-font-family);
  }

  .typography--body-xs {
    font: var(--typography-desktop-body-xs-font-size) / var(--typography-desktop-body-xs-line-height) var(--body-xs-font-family);
  }

  .typography--body-xs-bold {
    font: 700 var(--typography-desktop-body-xs-font-size) / var(--typography-desktop-body-xs-line-height) var(--body-xs-bold-font-family);
  }

  .typography--button-large, .typography--button-lg {
    font: var(--typography-desktop-button-lg-font-size) / var(--typography-desktop-button-lg-line-height) var(--button-lg-font-family);
  }

  .typography--button-medium, .typography--button-md {
    font: var(--typography-desktop-button-md-font-size) / var(--typography-desktop-button-md-line-height) var(--button-md-font-family);
  }

  .typography--button-small, .typography--button-sm {
    font: var(--typography-desktop-button-sm-font-size) / var(--typography-desktop-button-sm-line-height) var(--button-sm-font-family);
  }

  .typography--button-xs {
    font: var(--typography-desktop-button-xs-font-size) / var(--typography-desktop-button-xs-line-height) var(--button-xs-font-family);
  }

  .typography--menu-item {
    font: var(--typography-desktop-menu-item-font-size) / var(--typography-desktop-menu-item-line-height) var(--menu-item-font-family);
  }

  .typography--label {
    font: var(--typography-desktop-label-font-size) / var(--typography-desktop-label-line-height) var(--label-font-family);
  }

  .typography--helper-text {
    font: var(--typography-desktop-helper-font-size) / var(--typography-desktop-helper-line-height) var(--helper-font-family);
  }
}


@media (min-width: 1080px) {
  .author-card--author-detail .author-card__role {
    grid-area: 2 / 2;
  }
}


@media (min-width: 1080px) {
  .author-detail-wrapper {
    grid-column: lend / rdivider;
  }

  .author-card--author-detail {
    padding-bottom: var(--spacing-24);
  }

  .author-card--author-detail .social-media {
    grid-area: 1 / 3;
    justify-self: end;
  }

  .author-card--author-detail .author-card__mail-link {
    justify-self: end;
  }
}


@media (min-width: 1080px) {
  .filters {
    gap: var(--spacing-32);
  }
}


@media (min-width: 1080px) {
  .filters__header {
    margin: 0;
  }
}


@media (min-width: 1080px) {
  .load-more-disabled__title {
    font: var(--font-desktop-h4);
  }

  .load-more-disabled__subtitle {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 1080px) {
  .promobox-main > .promobox-article-main .article-card__image {
    width: 100%;
    height: 382px;
  }

  .promobox-main > .promobox-article-main .article-card__image > picture, .promobox-main > .promobox-article-main .article-card__image > picture > img {
    max-height: 382px;
  }
}


@media (min-width: 1080px) {
  .print__cover {
    gap: var(--spacing-12);
  }

  .print__cover > a > img {
    width: 128px;
    height: auto;
  }

  .print__cover .button {
    width: 128px;
  }
}


@media (min-width: 1080px) {
  .promo-subscription {
    align-self: flex-start;
  }
}


@media (min-width: 1080px) {
  .topic__custom-article html, .topic__custom-article body, .topic__custom-article .font-body {
    font: var(--font-desktop-body1);
  }

  .topic__custom-article h1, .topic__custom-article .heading-1 {
    font: var(--font-desktop-h1);
  }

  .topic__custom-article h2, .topic__custom-article .heading-2 {
    font: var(--font-desktop-h2);
  }

  .topic__custom-article h3, .topic__custom-article .heading-3 {
    font: var(--font-desktop-h3);
  }

  .topic__custom-article h4, .topic__custom-article .heading-4 {
    font: var(--font-desktop-h4);
  }

  .topic__custom-article h5, .topic__custom-article .heading-5 {
    font: var(--font-desktop-h5);
  }

  .topic__custom-article p {
    font: var(--font-desktop-body1);
  }

  .topic__custom-article strong {
    font: var(--font-desktop-body1-bold);
  }

  .topic__custom-article table thead {
    font: var(--font-desktop-body2-bold);
  }

  .topic__custom-article table tbody {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 1080px) {
  .tabs {
    scroll-margin-top: 188px;
  }
}


@media (min-width: 1080px) {
  .premium-card {
    height: 499px;
  }
}


@media (min-width: 1080px) {
  .search__form {
    margin-top: 0;
  }
}


@media (min-width: 1080px) {
  .cnc-gallery-html-body .cnc-branding .cnc-ads--leaderboard iframe:first-child {
    top: 0 !important;
  }
}


@media (min-width: 1080px) {
  .ad-position--desktop {
    display: block;
    overflow: hidden;
  }
}


@media (min-width: 1080px) {
  .ad-list-322, .ad-list-353 {
    grid-column: 1 / 3;
  }
}


@media (min-width: 1080px) {
  .ad-list-325 {
    grid-column: 1 / 3;
  }
}


@media (min-width: 1080px) {
  .sidebar-content {
    padding-bottom: var(--spacing-16);
    height: 100%;
  }

  .sidebar-content :not(.miniplayer, .miniplayer *) {
    top: var(--ad-top);
    position: sticky;
  }
}


@media (min-width: 1080px) {
  .miniplayer {
    aspect-ratio: 16 / 9;
    margin: var(--spacing-24) 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    display: flex;
    container-type: inline-size;
  }

  .miniplayer .vp-player {
    width: 330px;
  }
}


@media (min-width: 1080px) {
  .wiki-search {
    grid-column: 1 / 3;
  }
}


@media (min-width: 1080px) {
  .category-info__description html, .category-info__description body, .category-info__description .font-body {
    font: var(--font-desktop-body1);
  }

  .category-info__description h1, .category-info__description .heading-1 {
    font: var(--font-desktop-h1);
  }

  .category-info__description h2, .category-info__description .heading-2 {
    font: var(--font-desktop-h2);
  }

  .category-info__description h3, .category-info__description .heading-3 {
    font: var(--font-desktop-h3);
  }

  .category-info__description h4, .category-info__description .heading-4 {
    font: var(--font-desktop-h4);
  }

  .category-info__description h5, .category-info__description .heading-5 {
    font: var(--font-desktop-h5);
  }

  .category-info__description p {
    font: var(--font-desktop-body1);
  }

  .category-info__description strong {
    font: var(--font-desktop-body1-bold);
  }

  .category-info__description table thead {
    font: var(--font-desktop-body2-bold);
  }

  .category-info__description table tbody {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 1080px) {
  .category-info {
    grid-column: lend / rdivider;
  }

  .category-info h1 {
    font: var(--font-desktop-h2);
  }

  .category-info__menu > ul {
    gap: var(--spacing-32);
    font: var(--font-desktop-body2);
    margin: var(--spacing-32) 0 var(--spacing-24);
    border-bottom: 1px solid var(--other-divider);
    display: flex;
  }

  .category-info__menu > ul ul {
    opacity: 0;
    padding: 0 var(--spacing-8) var(--spacing-8);
    background-color: var(--background-default);
    text-align: right;
    pointer-events: none;
    border-bottom: 0;
    flex-direction: column;
    gap: 0;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0 2px 5px -3px #000;
  }

  .category-info__menu-item {
    white-space: nowrap;
    position: relative;
  }

  .category-info__menu-item--active {
    color: var(--text-primary);
    font: var(--font-desktop-body2-bold);
    border-bottom: 2px solid;
  }

  .category-info__menu-item .category-info__menu-item {
    opacity: 0;
    transition: all .2s ease-in-out;
    position: relative;
  }

  .category-info__menu-item .category-info__menu-item:nth-child(2n) {
    left: -50%;
  }

  .category-info__menu-item .category-info__menu-item:nth-child(odd) {
    left: 50%;
  }

  .category-info__menu-item:hover .category-info__menu-item {
    padding: var(--spacing-8);
    opacity: 1;
    border-bottom: 0;
    margin-left: auto;
    transition: all .2s ease-in-out;
    left: 0;
  }

  .category-info__menu-item.with-subcat {
    margin-left: auto;
  }

  .category-info__menu-item.with-subcat:hover {
    cursor: default;
  }

  .category-info__menu-item.with-subcat:hover ul {
    opacity: 1;
    pointer-events: initial;
    transition: opacity .2s ease-in-out;
  }

  .category-info__menu-item.with-subcat:hover .category-info__menu-item {
    width: 100%;
  }

  .category-info__menu-link {
    padding-bottom: var(--spacing-16);
    color: var(--text-secondary);
    text-decoration: none;
    display: block;
  }

  .category-info__menu-link:hover {
    color: var(--primary-main);
  }

  .category-info__menu-link:focus {
    background: var(--primary-outlined-hover-background);
  }

  .category-info__menu-item--active .category-info__menu-link {
    color: var(--text-primary);
  }

  .category-info__menu-item.with-subcat:hover .category-info__menu-link {
    padding-bottom: 0;
  }

  .category-info__menu-item.with-subcat:hover > .category-info__menu-link {
    color: var(--primary-main);
  }

  .category-info__subcat-vertical-list {
    z-index: 10;
  }

  .category-info__menu-arrow {
    transition: transform .2s ease-in-out;
    display: inline-block;
    transform: rotate(-90deg);
  }

  .category-info__menu-arrow path {
    fill: var(--text-secondary);
  }

  .category-info__menu-item:hover .category-info__menu-arrow {
    transition: transform .2s ease-in-out;
    transform: rotate(0);
  }

  .category-info__menu-item:hover .category-info__menu-arrow path {
    fill: var(--primary-main);
  }
}


@media (min-width: 1080px) {
  .forum__comment-head-info {
    gap: var(--spacing-16);
    flex-direction: row;
    align-items: center;
  }
}


@media (min-width: 1080px) {
  .profile-user {
    min-width: 680px;
  }

  .profile-user strong {
    font: var(--font-desktop-body1-bold);
  }

  .profile-user__cancel-reccuring strong .recurring-info-popup, .profile-user__cancel-newsletter strong .recurring-info-popup {
    font: var(--font-desktop-meta-regular);
  }

  .profile-user__voucher-title {
    font: var(--font-desktop-body1-bold);
  }

  .form-review__field label, .form-review__field textarea {
    font: var(--font-desktop-meta-regular);
  }
}


@media (min-width: 1080px) {
  .list-articles-wrapper--vertical {
    grid-column: 1 / 3;
  }
}


@media (min-width: 1080px) {
  .article-detail {
    grid: subgrid / subgrid;
    grid-area: 1 / 1 / 4 / 3;
    display: grid;
  }
}


@media (min-width: 1080px) {
  .article-detail__header {
    margin: 0 calc(-1 * var(--spacing-24));
    padding: var(--spacing-24);
  }

  .article-detail__header:has(.article-gallery-strip) {
    padding-bottom: 0;
  }
}


@media (min-width: 1080px) {
  .article-detail__top-img-description {
    padding: var(--spacing-8) var(--spacing-16) 0;
  }

  .article-detail__top-img-description:last-child {
    margin-bottom: var(--spacing-8);
  }
}


@media (min-width: 1080px) {
  .article-detail__content-body > p, .article-detail__content-body > h2, .article-detail__content-body > h3, .article-detail__content-body > h4, .article-detail__content-body > h5 {
    max-width: 620px;
  }
}


@media (min-width: 1080px) {
  .article-detail__content-body > ol, .article-detail__content-body > ul {
    max-width: 620px;
  }
}


@media (min-width: 1080px) {
  .article-detail__content-body > p:has(.quote) {
    max-width: 680px;
  }
}


@media (min-width: 1080px) {
  .article-detail__content-body > p > strong > .quote {
    max-width: 680px;
  }
}


@media (min-width: 1080px) {
  .article-gallery-strip__enter {
    font: var(--font-mobile-button-small);
  }
}


@media (min-width: 1080px) {
  .topic-banner {
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 1080px;
  }
}


@media (min-width: 1080px) {
  .topic-banner__label {
    font: var(--font-desktop-meta-bold);
  }
}


@media (min-width: 1080px) {
  .topic-banner__title {
    font: var(--font-desktop-h3);
  }
}


@media (min-width: 1080px) {
  .premium-print {
    margin-left: calc(var(--spacing-24) * -1);
    margin-right: calc(var(--spacing-24) * -1);
  }
}


@media (min-width: 1080px) {
  .video-box {
    grid-column: 1 / 3;
  }
}


@media (min-width: 1080px) {
  .list-article-author--multi .swiper__item {
    flex: 1;
  }
}


@media (min-width: 1080px) {
  .list-article-author--total-3 .swiper__item {
    flex: none;
    width: 246px;
  }
}


@media (min-width: 1080px) {
  .match-overview__row--footer {
    margin-left: 120px;
  }
}


@media (min-width: 1080px) {
  .match-overview-meta {
    align-items: flex-start;
    gap: var(--spacing-4);
    flex-flow: column;
    width: 105px;
    margin-top: 5px;
  }
}


@media (min-width: 1080px) {
  .match-overview__badge-status.hide-on-desktop {
    display: none;
  }
}


@media (min-width: 1080px) {
  .match-oveview-video-list__header {
    justify-content: space-between;
  }
}


@media (min-width: 1080px) {
  .detail-match-overview-bet__in {
    min-width: 688px;
  }
}


@media (min-width: 1080px) {
  .detail-match-overview-bet__button .button__text {
    font-size: var(--typography-desktop-subtitle-lg-font-size);
    letter-spacing: var(--typography-desktop-subtitle-lg-letter-spacing);
  }
}


@media (min-width: 1080px) {
  .detail-match-overview-bet__stream {
    margin-top: var(--spacing-24);
  }
}


@media (min-width: 1080px) {
  .day-result-datepicker {
    margin: 0;
    padding: 0;
  }
}